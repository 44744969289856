<template>
    <div>
      <button @click="triggerFileInput" class="button-excel text-white font-bold py-0 px-1 rounded w-full mb-3">
        Cargar Grupos y Usuarios con excel
      </button>
      <input type="file" @change="handleFileChange" ref="fileInput" style="display: none" accept=".xlsx, .xls">
      <ResultModal :isVisible="showModal" :message="modalMessage" :errors="modalErrors" @close="showModal = false" />
    </div>
  </template>
  
  
  <script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import ResultModal from './ResultModal.vue';
import { useToast } from 'vue-toastification';

export default {
  props: {
    eventoToken: String
  },
  components: {
    ResultModal
  },
  data() {
    return {
      showModal: false,
      modalMessage: '',
      modalErrors: []
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        this.processData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    },
    processData(data) {
    let errors = [];
    let userCount = 0;
    let groupCount = 0;
    const cleanedData = data.slice(1);
    const grouped = {};

    cleanedData.forEach((item, index) => {
        const puesto = item[2] && ['usuario', 'administrador'].includes(item[2].toLowerCase()) ? item[2].toLowerCase() : 'usuario';
        
            // Asegúrate de inicializar la empresa si es la primera vez que aparece.
            if (!grouped[item[3]]) {
                grouped[item[3]] = {
                    empresa: item[3],
                    asistentes: parseInt(item[4]) || 0,
                    conferencistas: parseInt(item[5]) || 0,
                    staff: parseInt(item[6]) || 0,
                    usuarios: []
                };
                groupCount++;
            }

            // Agrega usuario solo si tiene nombre y correo.
            if (item[0] && item[1]) {
                grouped[item[3]].usuarios.push({
                    nombre: item[0],
                    correo: item[1],
                    puesto: puesto
                });
                userCount++;
            } else {
                errors.push(`Fila ${index + 2}: Falta nombre o correo, usuario no agregado.`);
            }
        });

        this.uploadData(grouped, errors, userCount, groupCount);
    },


    async uploadData(grouped, errors, userCount, groupCount) {
      axios.post('/api/groups/bulk-upload', { grupos: Object.values(grouped), eventoToken: this.eventoToken })
        .then(() => {
          this.modalMessage = `Grupos creados: ${groupCount}, Usuarios creados: ${userCount}`;
          this.modalErrors = errors;
          this.showModal = true;
          this.toast.success('Grupos y usuarios cargados exitosamente.');
        })
        .catch(error => {
          console.error('Error uploading groups:', error);
          this.toast.error('Error al cargar los datos.');
        });
    }
  }
}
</script>

  
  <style scoped>
  .button-excel {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 5px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    /* margin: 4px 2px 4px 2px; */
    cursor: pointer;
  }
  .button-excel:hover{
    background-color: #378139; /* Green */

  }
  </style>
 