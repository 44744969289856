<template>
     <div class="logo">
        
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> <path d="M144 144l0 48 160 0 0-48c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192l0-48C80 64.5 144.5 0 224 0s144 64.5 144 144l0 48 16 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l16 0z"/></svg>


    </div>
</template>
<script>
</script>
<style lang="sass" scoped>
.logo
    // position: absolute
    top: 0
    backdrop-filter: blur(10px)
    background-color: rgba(255, 255, 255, 0.35)
    
    // left: 0
    // right: 0
    // margin: auto
    min-width: 100px
    min-height: 100px
    // background: #fff
    margin: 0!important
    border: 6px solid #fff
    border-radius: 50%
    padding: 35px
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    svg
        max-width: 60%
</style>