<template>
    <div class="container mx-auto p-4">
    <div  v-if="canView('superadministrador')" class="w-100 text-center mb-10">

        <button @click="showCreateModal = true" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Crear Evento
        </button>
        <CreateEventoModal v-if="showCreateModal" @close="showCreateModal = false" @saved="fetchEventos"/>
    </div>
        
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <router-link :to="`/lista-usuarios/${evento.token}`" v-for="evento in eventos" :key="evento.id_evento" class="rounded overflow-hidden shadow-lg bg-white hover:bg-gray-100 cursor-pointer">
          <div class="px-6 py-4">
            <div class="font-bold text-xl mb-2">{{ evento.nombre }}</div>
            <p class="text-gray-700 text-base">
              Click para ver grupos y usuarios.
            </p>
          </div>
          <div class="px-6 pt-4 pb-2">
            <button
                class="inline-block bg-blue-500 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">Ver Detalles
                
            </button> 
          </div>
        </router-link>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";

  import CreateEventoModal from '@/components/modals/eventos/CreateEventoModal.vue';
  export default {
    setup() {      
      const toast = useToast();
      return { toast }
    },
    components: {
        CreateEventoModal // Aquí se registra correctamente
    },
    data() {
      return {
        showCreateModal: false,
        eventos: [],
        role: null,
      };
    },
    
    mounted() {
        this.validateRole();    
        this.fetchEventos();
    },
    computed: {      
        roleToken() {
        return this.$store.state.roleToken;
        },
    },
    methods: {
      fetchEventos() {
        const token = this.$store.state.user.token;
        fetch(`/api/data-eventos/${token}`)
          .then(response => response.json())
          .then(data => {
            this.eventos = data;

            if (this.eventos.length === 1) {
              // Si solo hay un evento, redirigir automáticamente
              this.$router.push(`/lista-usuarios/${this.eventos[0].token}`);
            }
            // Si no hay eventos, el componente sigue su flujo normal mostrando la lista vacía
          })
          .catch(error => {            
            this.toast.error('Error al cargar los eventos: ' + error.message);
          });
      },

      async validateRole() {
      // console.log("Role token:", this.roleToken);

        if (!this.roleToken) {
            this.roleLoaded = true;
            return;
        }
        try {
            const response = await axios.post('/api/verify-role', {
            roleToken: this.roleToken
            });
            // console.log('Role response:', response.data); 
            this.role = response.data.role;
        } catch (error) {
            // console.error('Error verifying role:', error);
            this.role = null;
        } finally {
            this.roleLoaded = true;
        }
        },
      canView(expectedRole) {
            if (!this.roleLoaded) {
                return false;
            }
            const roleHierarchy = {
                'usuario': 1,
                'administrador': 2,
                'administradorevento': 3,
                'superadministrador': 4
            };

            return roleHierarchy[this.role] >= roleHierarchy[expectedRole];
        }
    }
  };
  </script>
  
<style lang="sass" scoped>
.w-100
    width: 100%

.content-page
    align-content: center
.grid
    border-radius: 20px
    background: linear-gradient(145deg, #f9f9f9, #f3f3f3)
    box-shadow:  20px 20px 20px #a5a5a5, -20px -20px 20px #dfdfdf

    .rounded
        margin: 15px
        backdrop-filter: blur(10px)
        background-color: rgba(255, 255, 255, 0.65)
        border-radius: 15px
        transition: all 0.2s
        &:hover
            box-shadow:  5px 5px 20px #a5a5a5, -5px -5px 20px #dfdfdf
        &:active
            transform: scale(0.98)
            
</style>