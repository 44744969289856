<template>
    <div class="container-modal mx-auto px-0 pb-3 flex justify-center">
      <!-- Botón para abrir el modal -->
      <button @click="showModal = true" class="boton-front bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded w-full">
        Agregar Super Administrador
      </button>
  
      <!-- Modal -->
      <div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <p class="text-2xl font-bold mb-5">Registrar Administrador de Evento</p>
          <form @submit.prevent="handleRegister" class="flex flex-col gap-4">
            <div>
              <label class="font-medium text-gray-700">Nombre</label>
              <input v-model="nombre" type="text" placeholder="Nombre completo" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
            </div>
            <div>
              <label class="font-medium text-gray-700">Dirección de email</label>
              <input v-model="email" type="email" placeholder="ejemplo@mail.com" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
            </div>
            <div>
              <label class="font-medium text-gray-700">Contraseña</label>
              <input v-model="password" type="password" placeholder="Debe tener al menos 8 caracteres" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
            </div>
            <div>
              <label class="font-medium text-gray-700">Confirmar contraseña</label>
              <input v-model="confirmPassword" type="password" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
            </div>
            <button type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Crear usuario</button>
            <button @click="showModal = false" class="mt-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useToast } from "vue-toastification";
  
  export default {
    props: ['eventoToken'],
    setup() {
      const toast = useToast();
      return { toast }
    },
    data() {
      return {
        nombre: '',
        email: '',
        password: '',
        confirmPassword: '',
        showModal: false,  // Controla la visibilidad del modal
      };
    },
    methods: {
      handleRegister() {
        if (!this.validateEmail(this.email)) {
          this.toast.warning("Introduce una dirección de correo electrónico válida.");
          return;
        }
        if (this.password !== this.confirmPassword) {
          this.toast.warning("Las contraseñas no coinciden.");
          return;
        }
        axios.post('/api/administrador-evento/register', {
          nombre: this.nombre,
          email: this.email,
          password: this.password,
          eventoToken: this.eventoToken,
          role: 'superadministrador',
        }).then(() => {
          this.toast.success('El super administrador se creo correctamente');
          this.resetForm();
          this.showModal = false;  // Cierra el modal después de registrar
        }).catch(error => {
          console.error('Error al registrar al administrador de evento:', error);
          this.toast.error("Error al crear el usuario.");
        });
      },
      validateEmail(email) {
        const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      resetForm() {
        this.nombre = '';
        this.email = '';
        this.password = '';
        this.confirmPassword = '';
      }
    }
  }
  </script>
  
  <style scoped>
  .container-modal {
    flex-direction: column;
  }
  .bg-white {
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .bg-opacity-75 {
    z-index: 2;
  }
  .boton-front {
    width: 100%;
    /* margin: 0 auto; */
    max-width: 400px;
  }
  </style>
  