<template>
    <div class="logo">       

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
   </div>
</template>
<script>
</script>
<style lang="sass" scoped>
.logo
   // position: absolute
   top: 0
   backdrop-filter: blur(10px)
   background-color: rgba(255, 255, 255, 0.35)
   
   // left: 0
   // right: 0
   // margin: auto
   min-width: 100px
   min-height: 100px
   // background: #fff
   margin: 0!important
   border: 6px solid #fff
   border-radius: 50%
   padding: 35px
   text-align: center
   display: flex
   justify-content: center
   align-items: center
   svg
       max-width: 60%
</style>