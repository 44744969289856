<template>
  <div>
    <button class="btn-svg !max-w-[30px] !shadow-none bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="openModal">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="!h-6 !w-6">
        <path d="M290.7 93.2l128 128-278 278-114.1 12.6C11.4 513.5-1.6 500.6 .1 485.3l12.7-114.2 277.9-277.9zm207.2-19.1l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"/>
      </svg>
      
    </button>
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center">
      <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative" @click.stop>
        <button @click="closeModal" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-lg leading-none">
          &times;
        </button>
        <h2 class="text-xl font-bold">Editar Grupo</h2>
        <form @submit.prevent="updateGroup" class="mt-4">
          <div class="mb-4">
            <label for="groupName" class="block text-gray-700 font-medium">Nombre del grupo:</label>
            <input id="groupName" v-model="groupName" type="text" required class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
          </div>
          <div class="grid grid-cols-3 gap-4 mb-5">
            <div>
              <label for="staff" class="block text-gray-700 font-medium">Staff:</label>
              <input id="staff" :value="staff" @input="updateValue('staff', $event.target.value)" type="number" min="0" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
            <div>
              <label for="conferencistas" class="block text-gray-700 font-medium">Expositores:</label>
              <input id="conferencistas" :value="conferencistas" @input="updateValue('conferencistas', $event.target.value)" type="number" min="0" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
            <div>
              <label for="asistentes" class="block text-gray-700 font-medium">Asistentes:</label>
              <input id="asistentes" :value="asistentes" @input="updateValue('asistentes', $event.target.value)" type="number" min="0" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
          </div>
          <div class="flex justify-between space-x-4">
            <button type="submit" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Actualizar</button>
            <button type="button" @click="confirmDelete" :class="{'bg-red-500 hover:bg-red-700': canDelete, 'bg-gray-400 cursor-not-allowed': !canDelete}" class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Eliminar Grupo</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from 'axios';

export default {
  setup() {      
      const toast = useToast();
      return { toast }
    },
  props: {
    group: Object,
    staff: Number,
    conferencistas: Number,
    asistentes: Number
  },
  data() {
    return {
      isModalOpen: false,
      groupName: ''
    };
  },
  methods: {
    openModal() {
      
      this.isModalOpen = true;
      this.groupName = this.group.group_name;
      this.canDelete = this.group.users.length === 0;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    updateValue(field, value) {
      this.$emit(`update:${field}`, Number(value));
    },
    updateGroup() {
      axios.post(`/api/groups/update`, {
        name: this.groupName,
        tokenGroup: this.group.group_id,
        cantStaff: String(this.staff),
        cantAsistente: String(this.asistentes),
        cantConferencista: String(this.conferencistas),
      }).then(response => {
        if (response.data.success) {
          this.$emit('groupUpdated', {
            ...this.group,
            group_name: this.groupName,
            staff: this.staff,
            asistentes: this.asistentes,
            conferencistas: this.conferencistas
          });
          // this.toast.success("Grupo actualizado correctamente");          
          this.closeModal();
        }
      }).catch(error => {
        console.error('Error updating group:', error);
      });
    },

    confirmDelete() {
      if (!this.canDelete) return;
      if (confirm('¿Estás seguro de que quieres eliminar este grupo?')) {
        axios.post(`/api/groups/delete/${this.group.group_id}`).then(response => {
          if (response.data.success) {
            this.$emit('groupDeleted', this.group.group_id);
            this.toast.success("Grupo borrado correctamente");          
            this.closeModal();
          }
        }).catch(error => {
          console.error('Error deleting group:', error);
        });
      }
    }
  }
};
</script>

<style scoped>
.btn-svg {
  display:flex;
  justify-content:center;
  align-items:center;
  svg {
    min-width: 15px;
    fill: #fff;
  }
}
.bg-opacity-40{
  z-index: 1;
}
</style>
