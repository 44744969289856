<template>
  <div class="page-content">
    <div v-if="loading">
      <p>Cargando...</p>
    </div>
  
    <div v-else-if="canEdit" class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md md:flex-1 md:min-h-[500px]">
      
    <p class="text-2xl font-bold mb-5">Editar Usuario</p>
    <form @submit.prevent="handleUpdate" class="flex flex-col gap-4">
        <div>
            <label class="font-medium text-gray-700">Nombre</label>
            <input v-model="nombre" type="text" placeholder="Nombre y apellido" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
        </div>
        <div>
            <label class="font-medium text-gray-700">Dirección de email</label>
            <input v-model="email" type="email" placeholder="ejemplo@mail.com" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
        </div>
        <div>
            <label class="font-medium text-gray-700">Contraseña</label>
            <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
        </div>
        <div>
            <label class="font-medium text-gray-700">Confirmar contraseña</label>
            <input v-model="confirmPassword" type="password" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
        </div>
        <div>
            <label class="font-medium text-gray-700">Rol</label>
            <select v-model="role" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                <option value="">-- Selecciona un rol --</option>
                <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
            </select>
        </div>
        <button type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Actualizar Información</button>
    </form>
</div>

  <VistaProhibida v-else />
</div>
</template>
<script>
import axios from 'axios';
import VistaProhibida from '@/views/VistaProhibida.vue';
export default {
  props: ['token'],
  components: {
    VistaProhibida
  },
  data() {
    return {
      nombre: '',
      email: '',
      role: '',
      password: '',
      confirmPassword: '',
      availableRoles: [],
      loading: true,
      canEdit: false,
      roleUserEdit: '',
    };
  },
  computed: {
    roleToken() {
      return this.$store.state.roleToken;
    }
  },
  async created() {
    this.loadData();
  },
  beforeRouteUpdate(to, from, next) {
    this.loadData(to.params.token); // asume que el parámetro de token está en la ruta como `/dashboard/user/edit/:token`
    next();
  },
  methods: {
    async loadData(token = this.token) {
      this.loading = true;
      await this.validatePermissions();
      await this.fetchUserData(token);
    },
    async fetchUserData() {
      try {
        const response = await axios.get(`/api/user/${this.token}`);
        const userData = response.data;
        
        if(this.roleUserEdit === 'superadministrador' || userData.id_token === this.$store.state.user.token ){
          this.nombre = userData.nombre;
          this.email = userData.email;
          this.role = userData.role;
          this.canEdit = true;
          this.loading = false;          
        } else if(this.roleUserEdit === 'administradorevento' && userData.role !== 'superadministrador'){
          this.nombre = userData.nombre;
          this.email = userData.email;
          this.role = userData.role;
          this.canEdit = true;
          this.loading = false;          
        } else if(this.roleUserEdit === 'administrador' && userData.role !== 'administradorevento' && userData.role !== 'superadministrador'){
          this.nombre = userData.nombre;
          this.email = userData.email;
          this.role = userData.role;
          this.canEdit = true;
          this.loading = false;          
        } else {          
          this.canEdit = false;
          this.loading = false;
        }
        
      } catch (error) {        
        this.canEdit = false;
        this.loading = false;
      }
    },
    async validatePermissions() {
      if (!this.roleToken) {
        this.loading = false;
        return;
      }
      try {
        const response = await axios.post('/api/verify-role', { roleToken: this.roleToken });
        const roleAuth = response.data.role;
        this.roleUserEdit = roleAuth;
        const currentUserToken = this.$store.state.user.token;
        this.canEdit = this.checkEditPermission(roleAuth, this.role, this.token === currentUserToken);
        this.availableRoles = this.getRolesBasedOnAuth(roleAuth);
      } catch (error) {
        console.error('Error verifying permissions:', error);
        this.loading = false;
      }
    },
    checkEditPermission(roleAuth, userRole, isSelf) {
      const roleHierarchy = {
        'usuario': 1,
        'administrador': 2,
        'administradorevento': 3,
        'superadministrador': 4
      };
      return roleAuth === 'superadministrador' || roleAuth === 'administradorevento'  || (isSelf && roleHierarchy[roleAuth] >= roleHierarchy[userRole]);
    },
    getRolesBasedOnAuth(roleAuth) {
      switch (roleAuth) {
        case 'superadministrador':
          return [{ value: 'superadministrador', text: 'Super Administrador' },
                  { value: 'administradorevento', text: 'Administrador de evento' },
                  { value: 'administrador', text: 'Administrador' },
                  { value: 'usuario', text: 'Usuario' }];
        case 'administradorevento':
          return [{ value: 'administradorevento', text: 'Administrador de evento' },
                  { value: 'administrador', text: 'Administrador' },
                  { value: 'usuario', text: 'Usuario' }];
        case 'administrador':
          return [{ value: 'administrador', text: 'Administrador' },
                  { value: 'usuario', text: 'Usuario' }];
        default:
          return [{ value: 'usuario', text: 'Usuario' }];
      }
    },
    handleUpdate() {
      if (!this.validateEmail(this.email)) {
        alert("Por favor, introduce un correo electrónico válido.");
        return;
      }
      if (this.password !== this.confirmPassword) {
        alert("Las contraseñas no coinciden.");
        return;
      }
      axios.post(`/api/user/update/${this.token}`, {
        nombre: this.nombre,
        email: this.email,
        role: this.role,
        token: this.token,
        password: this.password
      }).then(() => {
        alert('Información actualizada correctamente');
      }).catch(error => {
        console.error('Error updating user:', error);
        alert('Hubo un problema con tu solicitud de actualización.');
      });
    },
    validateEmail(email) {
      const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>
