4<template>
    <div class="flex items-center justify-center min-h-screenbg-404">
      <div class="text-center caja-blanca">
        <h1 class="text-6xl font-bold text-gray-900">403</h1>
        <p class="text-xl text-gray-800 mt-2">No tienes permisos para ver esta pagina</p>
        <p class="text-gray-700 mt-4">Lo sentimos, no tienes permisos para ver esta pagina, si consideras que esto es un error contacta a tu administrador</p>
        <router-link to="/" class="button mt-6 inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
          Volver al Inicio
        </router-link>
      </div>
    </div>
  </template>
  
  <style lang="sass" scoped>

    .caja-blanca
        padding: 20px 25px 15px 25px
        backdrop-filter: blur(10px)
        background-color: rgba(255, 255, 255, 0.35)
        border-radius: 20px
        width: 100%
        max-width: 529px
        margin-top: 20px
.button
    display: block
    width: 100%
    border: none            
    padding: 15px
    text-align: left            
    cursor: pointer
    font-size: 16px
    background: rgba(255,255,255, 0.6)
    color: #000
    margin-top: 15px
    font-size: 0.8em
    border-radius: 30px
    text-align: center
    transition: all 0.2s
    &:hover
        background: rgba(255,255,255, 0.9)
        transform: scale(0.98)
        box-shadow: 0 0 5px rgba(155,155,155, 0.5)
    &:active
        transform: scale(0.97)
        border: 2px solid rgba(0,0,0, 0.7)
</style>