<template>
  <div class="container mx-auto p-4" v-if="isLoaded">
    <form @submit.prevent="submitForm" class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md">
      <!-- Switch para Permitir Duplicados -->
      <div class="switch-container mb-4 flex items-center">
        <span class="mr-2 text-sm font-medium text-gray-700 cursor-pointer" @click="toggleSwitch">Permitir usuarios duplicados</span>
        <div class="relative cursor-pointer" @click="toggleSwitch">
          <div :class="switchBgClass" class="switch-bg"></div>
          <div :class="switchDotClass" class="switch-dot"></div>
        </div>
      </div>
      <hr>
      <hr>
      <br>
      <div class="mb-6">
        <label for="nombre" class="block mb-2 text-sm font-medium text-gray-700">Nombre del evento</label>
        <input type="text" id="nombre" v-model="evento.nombre" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
      </div>
      <div class="mb-6">
        <label for="fecha_inicio" class="block mb-2 text-sm font-medium text-gray-700">Fecha de corte</label>
        <input type="datetime-local" id="fecha_inicio" v-model="evento.fecha_inicio" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
      </div>
      <div class="mb-6">
        <label for="fecha_fin" class="block mb-2 text-sm font-medium text-gray-700">Fecha de fin</label>
        <input type="datetime-local" id="fecha_fin" v-model="evento.fecha_fin" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
      </div>
      <div class="mb-6">
        <label for="codigo" class="block mb-2 text-sm font-medium text-gray-700">Código (opcional)</label>
        <input type="text" id="codigo" v-model="evento.codigo" class="mt-1 w-full border-gray-300 rounded-md shadow-sm">
      </div>
      <button type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Guardar cambios
      </button>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      evento: {
        nombre: '',
        fecha_inicio: '',
        fecha_fin: '',
        codigo: '',
        permitir_duplicados: false
      },
      isLoaded: false
    };
  },
  computed: {
    switchBgClass() {
      return this.evento.permitir_duplicados ? 'bg-green-500' : 'bg-gray-600';
    },
    switchDotClass() {
      return this.evento.permitir_duplicados ? 'translate-x-9' : 'translate-x-1';
    }
  },
  methods: {
    toggleSwitch() {
      this.evento.permitir_duplicados = !this.evento.permitir_duplicados;
    },
    submitForm() {
      const payload = {
        ...this.evento,
        permitir_duplicados: this.evento.permitir_duplicados ? 1 : 0
      };
      fetch(`/api/eventos/update/${this.evento.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      })
      .then(response => response.json())
      .then(data => {
        this.toast.success('Evento actualizado: ' + data.status);
        this.$router.push('/eventos');
      })
      .catch(() => {
        this.toast.error('Error al actualizar el evento');
      });
    },
    fetchEvento() {
      fetch(`/api/eventos/${this.$route.params.token}`)
        .then(response => response.json())
        .then(data => {
          this.evento = {...data, permitir_duplicados: data.permitir_duplicados === "1"};
          this.evento.fecha_inicio = this.evento.fecha_inicio.slice(0, 16);
          this.evento.fecha_fin = this.evento.fecha_fin.slice(0, 16);
          this.isLoaded = true;
        })
        .catch(error => {
          this.toast.error('Error al cargar el evento: ' + error.message);
        });
    }
  },
  created() {
    this.toast = useToast();
    this.fetchEvento();
  }
};
</script>

<style lang="sass">
.switch-container
  display: flex
  align-items: center
  cursor: pointer

.switch-bg
  width: 4rem
  height: 2rem
  border-radius: 9999px
  transition: background-color 0.2s

.switch-dot
  width: 1.5rem
  height: 1.5rem
  background-color: white
  border-radius: 50%
  position: absolute
  top: 0.25rem
  transition: transform 0.2s
</style>
