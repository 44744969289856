<template>
  <div class="appvue">
    <NavBar v-if="showNav"/>
    <router-view class="content-page"/>
  </div>
</template>

<script>
import NavBar from '@/components/navbar/NavBar.vue';

export default {
  components: {
    NavBar
  },
  computed: {
    // Verifica la ruta actual y decide si mostrar o no la navegación
    showNav() {
      // Lista de rutas donde no se mostrará el nav
      const hideNavRoutes = ['/login', '/registrarse', '/solicitar-recuperacion', '/expired-recovery','/cambiar_pass'];
      // Verifica también si la página actual es una 404
      return !hideNavRoutes.includes(this.$route.path) && this.$route.name !== 'NotFound';
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.content-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 70px; /* Ajusta este valor según la altura de tu NavBar */
}
.container {
  padding-top: 80px!important; /* Ajusta este valor según la altura de tu NavBar */

}
nav {
  padding: 30px;
  width: 100%;
  
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
