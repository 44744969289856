import { createApp } from 'vue'
import App from './App.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles/tailwind.css'

const app = createApp(App)
app.use(store)
app.use(router)

app.use(Toast, {
    // Opciones globales
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,    
    closeButton: 'button',
    icon: true,
    rtl: false,
    maxToasts: 10
  });


  // Hacer que Toastify esté disponible globalmente
// app.config.globalProperties.$toast = app.config.globalProperties.$toast;

app.mount('#app')
