import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isLoggedIn: false,    
    isFirstLoad: true,
    user: null,
    isAuthenticated: false,
    roleToken: '', // Default role
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },    
    setUser(state, userData) {
      state.user = userData;
      state.isAuthenticated = true;
      state.isLoggedIn = true; // Asegura que esto también se establezca
      state.roleToken = userData.role; // Set user role
    },
    clearUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoggedIn = false; // Actualiza isLoggedIn aquí
      state.roleToken = ''; // Reset role to default
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
      commit('setLoginState', true); // Asegúrate de que también se establece isLoggedIn
      // this.$router.push('/dashboard');
    },
    logout({ commit }) {
      commit('clearUser'); // Limpia el usuario y otros estados relevantes
      commit('setLoginState', false); // Establece el estado de inicio de sesión como falso
      // this.$router.push('/'); // Redirige al usuario a la página de inicio de sesión
    },    
  },  
  plugins: [
    createPersistedState({
      paths: ['isLoggedIn', 'user', 'isAuthenticated', 'roleToken'], // Agrega 'roleToken' aquí
    }),
  ],
  
});
