<template>
  <transition name="fade">
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg p-4 max-w-lg w-full">
        <form @submit.prevent="submitForm">
          <!-- Switch para Permitir Duplicados -->
          <div class="switch-container mb-4 flex items-center">
            <span class="mr-2 text-sm font-medium text-gray-700 cursor-pointer" @click="toggleSwitch">Permitir usuarios duplicados</span>
            <div class="relative cursor-pointer" @click="toggleSwitch">
              <div :class="switchBgClass" class="switch-bg"></div>
              <div :class="switchDotClass" class="switch-dot"></div>
            </div>
          </div>
          <hr>
          <hr class="mb-5">
          <!-- Nombre del Evento -->
          <div class="mb-4">
            <label for="nombre" class="block text-sm font-medium text-gray-700">Nombre del evento</label>
            <input type="text" id="nombre" v-model="evento.nombre" class="input-style" required>
          </div>

          <!-- Fecha de Inicio -->
          <div class="mb-4">
            <label for="fecha_inicio" class="block text-sm font-medium text-gray-700">Fecha de corte</label>
            <input type="datetime-local" id="fecha_inicio" v-model="evento.fecha_inicio" class="input-style" required>
          </div>

          <!-- Fecha de Fin -->
          <div class="mb-4">
            <label for="fecha_fin" class="block text-sm font-medium text-gray-700">Fecha de fin</label>
            <input type="datetime-local" id="fecha_fin" v-model="evento.fecha_fin" class="input-style" required>
          </div>

          <!-- Código del Evento (opcional) -->
          <div class="mb-4">
            <label for="codigo" class="block text-sm font-medium text-gray-700">Código (opcional)</label>
            <input type="text" id="codigo" v-model="evento.codigo" class="input-style">
          </div>

          <!-- Botones de Acción -->
          <button type="button" @click="$emit('close')" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Cancelar</button>
          <button type="submit" class="ml-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Crear Evento</button>
        </form>
      </div>
    </div>
  </transition>
</template>
<script>
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      evento: {
        nombre: '',
        fecha_inicio: '',
        fecha_fin: '',
        codigo: '',
        permitir_duplicado: false
      }
    };
  },
  computed: {
    switchBgClass() {
      return this.evento.permitir_duplicado ? 'bg-green-500' : 'bg-gray-600';
    },
    switchDotClass() {
      return this.evento.permitir_duplicado ? 'translate-x-9' : 'translate-x-1';
    }
  },
  methods: {
    toggleSwitch() {
      this.evento.permitir_duplicado = !this.evento.permitir_duplicado;
    },
    submitForm() {
      const payload = {
        ...this.evento,
        permitir_duplicado: this.evento.permitir_duplicado ? 1 : 0
      };
      fetch('/api/eventos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      })
      .then(response => response.json())
      .then(data => {
        this.toast.success('Evento creado: ' + data.status);
        this.$emit('saved');
        this.$emit('close');
      })
      .catch(() => {
        this.toast.error("Error al crear el evento");
      });
    }
  },
  setup() {
    const toast = useToast();
    return { toast };
  }
};
</script>

<style lang="sass">
.switch-container
  display: flex
  align-items: center
  cursor: pointer
  // padding-bottom: 20px
  div.cursor-pointer
    margin-left: auto


.switch-bg
  width: 4rem
  height: 2rem
  border-radius: 9999px
  transition: background-color 0.2s

.switch-dot
  width: 1.5rem
  height: 1.5rem
  background-color: white
  border-radius: 50%
  position: absolute
  top: 0.25rem
  transition: transform 0.2s

.input-style
  display: block
  width: 100%
  padding: 0.625rem
  border: 1px solid #cccccc
  border-radius: 0.375rem
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
  &:focus
    outline: none
    border-color: #3182ce
    ring: #3182ce

.btn-blue
  background-color: #2563eb
  &:hover
    background-color: #1d4ed8

.btn-red
  background-color: #ef4444
  &:hover
    background-color: #dc2626
</style>