<template>
    <div class="contenedor-form-general">
        <div class="fondologin"></div>
        <LogoForms></LogoForms>
        <div class="contenedor-form">
            <p class="font-bold titulo">Crear cuenta</p>
            <form @submit.prevent="handleRegister">
                <div class="correo">
                    <span>Tu nombre</span>
                    <input v-model="nombre" type="text" placeholder="Nombre y apellido">
                </div>
                <div class="correo">
                    <span>Dirección de email</span>
                    <input v-model="email" type="email" placeholder="ejemplo@mail.com">
                </div>
                <div class="correo">
                    <span>Contraseña</span>
                    <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres">
                </div>
                <div class="correo">
                    <span>Confirmar contraseña</span>
                    <input v-model="confirmPassword" type="password">
                </div>
                <div class="correo">
                    <span>Clave autorización</span>
                    <input v-model="clave" type="text" :disabled="isDisabled" @input="toUpper">

                </div>
                <button type="submit">Continuar</button>
            </form>
            <p class="terminos">Al crear una cuenta aceptas las <a href="/condiciones-de-uso">Condiciones de Uso</a> y el <a href="/aviso-de-privacidad">Aviso de privacidad</a>.</p>
        </div>
        <div class="caja-blanca">
            <p class="font-bold titulo">¿Ya tienes una cuenta?</p>
            <button @click="goToLogin">Iniciar sesión</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import LogoForms from './LogoForms/LogoForms.vue';
import { useToast } from "vue-toastification";
 
 
export default {
    setup() {      
      const toast = useToast();
      return { toast }
    },
    components: {
        LogoForms
    },
    props: {
        claveProp: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            email: '',
            password: '',
            nombre: '',
            confirmPassword: '',
            clave: '',
        };
    },
    computed: {
        isDisabled() {
            return !!this.claveProp;  // Retorna true si claveProp no es vacío, false de lo contrario
        }
    },
    methods: {
        handleRegister() {
            if (!this.validateEmail(this.email)) {
                this.toast.warning("Por favor, introduce un correo electrónico válido.");
                return;
            }
            if (this.password !== this.confirmPassword) {
                this.toast.warning("Las contraseñas no coinciden.");
                return;
            }
            axios.post('/api/register', {
                nombre: this.nombre,
                clave: this.clave,
                email: this.email,
                password: this.password,
            }).then(response => {
                if (response.data.success) {
                    this.toast.success(response.data.success);
                    this.goToLogin();
                } else if(response.data.warning){
                    this.toast.warning(response.data.warning);
                    this.goToLogin();
                    
                }else {                    
                    this.toast.error(response.data.error);
                }
            }).catch(() => {                
                this.toast.error('No se pudo crear el usuario, intente de nuevo.');
                
            });
        },
        validateEmail(email) {
            const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        goToLogin() {
            this.$router.push('/login');
        },
        toUpper() {
            this.clave = this.clave.toUpperCase();
        }
    },
    mounted() {
        console.log("Prop value:", this.claveProp); // Esto ayudará a verificar si el valor se pasa correctamente.
        if (this.claveProp) {
            this.clave = this.claveProp.toUpperCase();
        }
    }
}
</script>


<style lang="sass" scoped >
.fondologin
    position: fixed
    min-height: 150vh
    min-width: 100vw
    z-index: -2
    background-repeat: no-repeat
    background-attachment: fixed
    background-size: cover
    background-position: top
    background-image: url(~@/assets/forms/jakob-dalbjorn-cuKJre3nyYc-unsplash.jpg)!important
    // backdrop-filter: blur(10px)
    // background-color: rgba(255, 255, 255, 0.35)
.contenedor-form-general
    // backdrop-filter: blur(30px)
    // background-color: rgba(255, 255, 255, 0.5)
    // backdrop-filter: blur(10px)
    // background-color: rgba(255, 255, 255, 0.35)
    width: 95%
    margin: 0 auto
    max-width: 500px
    padding: 20px
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    height: 100%
    border-radius: 20px
    position: relative
    // padding-top: 140px

    .contenedor-form, .caja-blanca
        padding: 20px 25px 15px 25px
        backdrop-filter: blur(10px)
        background-color: rgba(255, 255, 255, 0.35)
        border-radius: 20px
        width: 100%
        max-width: 529px
        margin-top: 20px
        .titulo
            font-size: 32px
            text-align: left
            margin-bottom: 20px
            
        form 
            position: relative
            text-align: left
            .correo, .password
                span
                    display: block
                    width: 100%
                    margin: 0
                    padding: 0
                    font-size: 20px
                    font-weight: bold
                    margin: 5px 0 0 0
                input
                    width: 100%
                    border: 2px solid rgba(120,120,120, 0.6)
                    padding: 10px 15px
                    border-radius: 30px
                    background: rgba(255,255,255, 0.5)
                    transition: all 0.2s
                    margin-bottom: 10px                    
                    &:focus, &:active                                                
                        background: #fff
                        outline: none
                        border: 2px solid rgba(200,200,200, 1)                        
                        box-shadow: 0 0 3px rgba(155,155,155, 0.8)
                    //   border-bottom: 2px solid #999!important
                      
                      
            
            .password
                margin-top: 15px
        button
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
            background: rgba(255,255,255, 0.6)
            color: #000
            margin-top: 15px
            font-size: 0.8em
            border-radius: 30px
            text-align: center
            transition: all 0.2s
            &:hover
                background: rgba(255,255,255, 0.9)
                transform: scale(0.98)
                box-shadow: 0 0 5px rgba(155,155,155, 0.5)
            &:active
                transform: scale(0.95)
                border: 1px solid rgba(0,0,0, 0.7)
        .terminos, .pass-olvidado
            font-size: 0.7em
            max-width: 100%
            margin: 20px auto
            a
                color: #555
                text-decoration: none   
                cursor: pointer
                font-weight: bold
                transition: all 0.2s
                text-decoration: underline
                &:hover
                  color: #000
                  
        .pass-olvidado
            display: flex
            align-items: center
            svg
                transform: scale(0.9)
                margin-left: 5px
        .otros-inicios
            button 
                margin-bottom: 15px                
                display: flex
                align-items: center
                justify-content: centera
                svg
                    transform: scale(0.9)
                    margin-right: 10px
            .google
                background: #529BFE
                color: #fff
            .facebook
                background: #184075
                color: #fff
    .caja-blanca, .contenedor-form
        p
            text-align: center!important
        // button
        //     background: #000
        //     text-align: center
        //     color: #fff
/* Animaciones */
// .slide-fade-enter-active, .slide-fade-leave-active
//     transition: all 0.2s ease

// .slide-fade-enter, .slide-fade-leave-to /* Inicio y fin de estado para enter/leave */
//     transform: translateY(10px)
//     opacity: 0

.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter, .fade-leave-to
    opacity: 0
.move-up-enter-active, .move-up-leave-active
    transition: transform 0.2s ease, opacity 0.2s ease
.move-up-enter, .move-up-leave-to
    transform: translateY(-20px)
    opacity: 0
.slide-fade-enter-active, .slide-fade-leave-active
    transition: all 0.2s ease
.slide-fade-enter, .slide-fade-leave-to
    transform: translateY(10px)
    opacity: 0
.email-placeholder
    cursor: pointer
    position: absolute
    line-height: 0
    opacity: 0
    // top: 0
    // display: none
    height: 0
    transition: all 0.2s
.email-placeholder.active
    display: flex
    position: relative
    height: auto
    line-height: 1
    opacity: 1
.password
    opacity: 0
.password.pass-active
    opacity: 1
.logo 
  width: fit-content
  margin: 20px
  padding: 10px
  font-size: 24px 
  font-family: Arial, Helvetica, sans-serif
  font-weight: bold
  text-align: right
  line-height: 0.6
</style>

