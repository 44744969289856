<template>
    <div class="logo">       

       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
   </div>
</template>
<script>
</script>
<style lang="sass" scoped>
.logo
   // position: absolute
   top: 0
   backdrop-filter: blur(10px)
   background-color: rgba(255, 255, 255, 0.35)
   
   // left: 0
   // right: 0
   // margin: auto
   min-width: 100px
   min-height: 100px
   // background: #fff
   margin: 0!important
   border: 6px solid #fff
   border-radius: 50%
   padding: 35px
   text-align: center
   display: flex
   justify-content: center
   align-items: center
   svg
       max-width: 60%
</style>