<template>
    <div class="flex justify-center items-center mb-10">
      <a
        href="/Plantilla para usuarios.xlsx"
        download="Plantilla para usuarios.xlsx"
        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Descargar plantilla de Excel para carga de usuarios (Staff, Expositores, Asistentes)
      </a>
    </div>
  </template>
  
  <script>
  export default {
  };
  </script>
  
  <style scoped>
  /* Aquí puedes agregar estilos adicionales si es necesario */
.bg-green-500[data-v-7776b8bc] {
background-color: #10b981;
}
  </style>
  