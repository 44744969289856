<template>
    <transition name="fade">
      <div class="modal-backdrop" v-if="isVisible">
        <div class="modal-content">
          <h2>Resultado de la Carga</h2>
          <p>{{ message }}</p>
          <div v-if="errors.length > 0">
            <h3>Errores:</h3>
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </div>
          <button @click="close">Cerrar</button>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: ['isVisible', 'message', 'errors'],
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  button {
    display: block;
    background: #35a309;
    color: #fff;
    border-radius: 5px;
    margin: 10px auto;
    padding: 7px 25px;
  }
  </style>
  