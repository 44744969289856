<template>
  <div class="container mx-auto p-4">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Nombre
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fecha de inicio
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Fecha de fin
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    P. Duplicados
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Código
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="evento in eventos" :key="evento.id_evento">
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ evento.nombre }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ formatDate(evento.fecha_inicio) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ formatDate(evento.fecha_fin) }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-center">
                    <span v-if="evento.permitir_duplicados === '1'" class="text-green-500">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>
                     </span>
                    <span v-else class="text-gray-500-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    </span>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    {{ evento.codigo || 'N/A' }}
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <router-link :to="'/eventos/' + evento.token" class="text-indigo-600 hover:text-indigo-900">Edit</router-link>
                    <button @click="deleteEvento(evento.token)" class="text-red-600 hover:text-red-900 ml-4">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <button @click="showCreateModal = true" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Crear Evento
    </button>
    <CreateEventoModal v-if="showCreateModal" @close="showCreateModal = false" @saved="fetchEventos"/>
  </div>
</template>

<script>
import CreateEventoModal from '@/components/modals/eventos/CreateEventoModal.vue';
import { useToast } from "vue-toastification";

export default {
  components: {
    CreateEventoModal
  },
  data() {
    return {
      eventos: [],
      showCreateModal: false,
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString('es-MX', {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric'
      });
    },
    fetchEventos() {
      fetch('/api/eventos')
        .then(response => response.json())
        .then(data => {
          this.eventos = data;
        })
        .catch(() => {
          this.toast.error("Error al cargar los eventos");
        });
    },
    deleteEvento(token) {
      fetch(`/api/eventos/delete/${token}`, {
        method: 'POST'
      })
        .then(response => response.json())
        .then(result => {
          this.toast.success('Evento eliminado: ' + result.status);
          this.fetchEventos();
        })
        .catch(() => {
          this.toast.error("Error al eliminar el evento");
        });
    }
  },
  created() {
    this.toast = useToast();
    this.fetchEventos();
  },
};
</script>

<style lang="sass">
.container
  flex-direction: column
.text-green-500
  display: flex
  align-items: center
  justify-content: center
  svg
  
    max-height: 20px
    fill: #22c55e
.text-gray-500-svg
  display: flex
  align-items: center
  justify-content: center
  svg
    max-height: 20px
    fill: gray
</style>
