<template>
  <div>
    <button @click="openModal" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-3">
      Crear grupo
    </button>
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-40 flex justify-center items-center">
      <div class="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative" @click.stop>
        <button @click="closeModal" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-lg leading-none">
          &times;
        </button>
        <form @submit.prevent="createGroup">
          <div class="mb-4">
            <label for="groupName" class="block text-gray-700 font-medium">Nombre del grupo:</label>
            <input id="groupName" v-model="groupName" type="text" required class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
          </div>
          <!-- <div class="mb-4">
            <label for="groupDescription" class="block text-gray-700 font-medium">Descripción:</label>
            <textarea id="groupDescription" v-model="groupDescription" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2"></textarea>
          </div> -->
          <div class="grid grid-cols-3 gap-4 mb-5">
            <div>
              <label for="cantStaff" class="block text-gray-700 font-medium"># Staff</label>
              <input id="cantStaff" v-model="cantStaff" type="number" min="0" max="99" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
            <div>
              <label for="cantConferencista" class="block text-gray-700 font-medium"># Expositores</label>
              <input id="cantConferencista" v-model="cantConferencista" type="number" min="0" max="99" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
            <div>
              <label for="cantAsistente" class="block text-gray-700 font-medium"># Asistentes</label>
              <input id="cantAsistente" v-model="cantAsistente" type="number" min="0" max="99" class="mt-1 w-full border border-gray-300 rounded-md shadow-sm px-4 py-2">
            </div>
          </div>
          <button type="submit" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full">Crear</button>
        </form>
      </div>
    </div>
  </div>
</template>


<script>

import axios from 'axios';
import { mapState } from 'vuex';
import { useToast } from "vue-toastification";

export default {
  setup() {      
      const toast = useToast();
      return { toast }
    },
  data() {
    return {
      isModalOpen: false,
      groupName: '',
      groupDescription: '',
      cantStaff: 0,
      cantAsistente: 0,
      cantConferencista: 0,
    };
  },
  computed: {
    ...mapState({
      authToken: state => state.user.token  // Asumiendo que el token se almacena aquí
    })
  },
  props: ['eventoToken'],
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    checkOutsideClick(event) {
      if (event.target.classList.contains('modal')) {
        this.closeModal();
      }
    },
    createGroup() {
      axios.post('/api/groups/create', {
          name: this.groupName,
          description: this.groupDescription,
          cantStaff: this.cantStaff,
          cantAsistente: this.cantAsistente,
          cantConferencista: this.cantConferencista,
          tokenEvento: this.eventoToken,
          tokenUser: this.authToken
      }).then(response => {
          this.$emit('groupCreated', response.data);
          this.toast.success("Grupo creado correctamente");          
          this.closeModal();
      }).catch(error => {
          console.error('Error creating group:', error);
      });
    }
  }
}
</script>
<style scoped>

.bg-opacity-40{
  z-index: 31;
}
</style>
