<template>
  <div>
    <!-- Overlay -->
    <div v-if="menuOpen" class="bg-black opacity-50 fixed inset-0 z-40" @click="toggleMenu"></div>

    <!-- Sidebar -->
    <aside :class="`transform top-0 left-0 w-64 md:max-w-xs bg-gray-800 text-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50 ${menuOpen ? 'translate-x-0' : '-translate-x-full'}`" @click.stop>
      <div class="flex justify-between items-center p-4 border-b border-gray-300">
        <span class="text-lg font-semibold">Gestión</span>
        <button @click="toggleMenu" class="text-gray-400 hover:text-gray-100">&times;</button>
      </div>
      <ul class="flex flex-col p-4">
        <li><router-link to="/lista-usuarios" class="block p-4 hover:bg-gray-700" @click="closeMenu">Gestión de invitados</router-link></li>        
        <li v-if="canView('superadministrador')" ><router-link to="/eventos" class="block p-4 hover:bg-gray-700" @click="closeMenu">Gestión de eventos</router-link></li>
        <li>
          <router-link 
            :to="'/dashboard/user/edit/' + $store.state.user.token" 
            class="block p-4 hover:bg-gray-700" 
            @click="closeMenu"
          >
            Editar mi perfil
          </router-link>
        </li>

        <li><a href="#" class="block p-4 hover:bg-gray-700" @click="logoutAndClose">Cerrar sesión</a></li>
      </ul>
    </aside>

    <!-- Main Navbar -->
    <nav class="bg-gray-800 py-0 text-white fixed top-0 left-0 right-0 z-30">
      <div class="max-w-6x2 mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <button @click="toggleMenu" class="p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">&#9776;</button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      role: null,
      menuOpen: false
      
    };
  },
  computed: {      
    roleToken() {
      return this.$store.state.roleToken;
    },
  },
  mounted() {
    this.validateRole();    
  },
  methods: {
    ...mapActions(['logout']),
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      if (this.menuOpen) {
        this.menuOpen = false;
      }
    },
    logoutAndClose() {
      this.logout();
      this.closeMenu();
    },
    async validateRole() {
      // console.log("Role token:", this.roleToken);

      if (!this.roleToken) {
        this.roleLoaded = true;
        return;
      }
      try {
        const response = await axios.post('/api/verify-role', {
          roleToken: this.roleToken
        });
        // console.log('Role response:', response.data); // Agregar un log para depuración
        this.role = response.data.role;
      } catch (error) {
        // console.error('Error verifying role:', error);
        this.role = null;
      } finally {
        this.roleLoaded = true;
      }
    },
    canView(...expectedRoles) {
        if (!this.roleLoaded) {
            return false;
        }
        const roleHierarchy = {
            'usuario': 1,
            'administrador': 2,
            'administradorevento': 3,
            'superadministrador': 4
        };

        return expectedRoles.some(role => roleHierarchy[this.role] >= roleHierarchy[role]);
    }
  }
}
</script>
