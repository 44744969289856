<template>
    <div v-if="isAuthorized" class="container mx-auto px-4 py-6 flex flex-wrap justify-center gap-4">
        <div class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md md:flex-1 md:min-h-[500px]">
            <p class="text-2xl font-bold mb-5">Crear cuenta en el grupo</p>
            <form @submit.prevent="handleRegister" class="flex flex-col gap-4">
                <div>
                    <label class="font-medium text-gray-700">Nombre</label>
                    <input v-model="nombre" type="text" placeholder="Nombre y apellido" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                </div>
                <div>
                    <label class="font-medium text-gray-700">Dirección de email</label>
                    <input v-model="email" type="email" placeholder="ejemplo@mail.com" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                </div>
                <div>
                    <label class="font-medium text-gray-700">Contraseña</label>
                    <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                </div>
                <div>
                    <label class="font-medium text-gray-700">Confirmar contraseña</label>
                    <input v-model="confirmPassword" type="password" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                </div>
                <div>
                    <label class="font-medium text-gray-700">Rol</label>
                    <select v-model="role" class="mt-1 w-full border-gray-300 rounded-md shadow-sm" required>
                        <option value="">-- Selecciona un rol --</option>
                        <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <button type="submit" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Crear usuario</button>
            </form>             
        </div>
        <div class="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-md md:flex-1 md:min-h-[500px]">
            <p class="text-2xl font-bold mb-5">Crear link de registro</p>
            <form @submit.prevent="handleCreateKey" class="flex flex-col gap-4">
                <div>
                    <label class="font-medium text-gray-700">Rol</label>
                    <select v-model="selectedRoleKey" required class="mt-1 w-full border-gray-300 rounded-md shadow-sm">
                        <option value="">-- Selecciona un rol --</option>
                        <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Generar clave</button>
            </form>
            <table v-if="keys.length > 0" class="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr class="bg-gray-200">
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Clave</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Permiso</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="key in keys" :key="key.id_token_clave">
                        <td class="px-6 py-4 whitespace-nowrap"><a :href="`https://sponsor.bmotik.work/registrarse/${key.id_token_clave}`" target="_blank" class="text-blue-500 hover:text-blue-700">{{key.id_token_clave}}</a></td>
                        <td class="px-6 py-4 whitespace-nowrap">{{ key.rol }}</td>
                        <td class="px-6 py-4 whitespace-nowrap">
                            <button @click="deleteKey(key.id_token_clave)" class="text-red-500 hover:text-red-700">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-else class="text-center">No hay claves generadas.</p>
        </div>
        <div class="mt-6 w-full text-center">
            <button @click="goBack" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Volver</button>
        </div>
    </div>
</template>



<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
export default {
    setup() {      
      const toast = useToast();
      return { toast }
    },
    props: ['groupId'],
    data() {
        return {
            nombre: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            availableRoles: [],
            keys: [],
            selectedRoleKey: '',
            isNotDashboardRoot: true,
            isAuthorized: false,  // Controla la visibilidad del componente
        };
    },
    components: {
        // HomeButton,
    },
    watch: {
        roleToken(newToken) {
            if (newToken) {
            this.validateRole();
            }
        }
    },
    computed: {      
      roleToken() {
        return this.$store.state.roleToken;
      }
    },
    created() {
        this.setAvailableRoles();
        
        this.fetchKeys();
    },
    methods: {
        handleRegister() {
            if (!this.nombre || !this.email || !this.password || !this.confirmPassword || !this.role) {
                this.toast.error("Todos los campos son obligatorios.");
                return;
            }
            if (!this.validateEmail(this.email)) {
                this.toast.warning("Introduce una dirección de correo electrónico válida.");
                return;
            }
            if (this.password !== this.confirmPassword) {
                this.toast.warning("Las contraseñas no coinciden.");
                return;
            }

            axios.post('/api/register', {
                nombre: this.nombre,
                email: this.email,
                password: this.password,
                groupId: this.groupId,
                role: this.role
            }).then(response => {
                this.toast.success(response.data.success);
                this.nombre = '';
                this.email = '';
                this.password = '';
                this.confirmPassword = '';
                this.role = '';
            }).catch(error => {
                console.error('Error creating user:', error);
                this.toast.error("Error al crear el usuario.");
            });
        },
        validateEmail(email) {
            const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        handleCreateKey() {
            axios.post('/api/create-key', {
                groupId: this.groupId,
                role: this.selectedRoleKey
            }).then(response => {
                if (response.data.success) {
                    this.toast.success('Clave generada exitosamente');
                    this.keys.push(response.data.key);
                } else {                    
                    this.toast.error('Hubo un problema con la generación de la clave.');
                }
            }).catch(() => {
                this.toast.error('Hubo un problema con la generación de la clave.');
            });
        },
        fetchKeys() {
            axios.get(`/api/keys/${this.groupId}`).then(response => {
                this.keys = response.data.keys;
            }).catch(() => {
                this.toast.error('Error al cargar links de registro.');
                
            });
        },
        deleteKey(token) {
            axios.post('/api/delete-key', { token }).then(response => {
                if (response.data.success) {
                    // Filtra la clave eliminada basándote en su token
                    this.keys = this.keys.filter(key => key.id_token_clave !== token);
                    this.toast.success('Clave eliminada con éxito');
                } else {
                    this.toast.error('Error al eliminar la clave');
                }
            }).catch(error => {
                console.error('Error eliminando clave:', error);
                this.toast.error('Error al eliminar la clave');                
            });
        },

        async setAvailableRoles() {
            
            // console.log("Role token:", this.roleToken);
            console.log("Token del grupo:", this.groupId);

            if (!this.roleToken) {
                this.roleLoaded = true;
                return;
            }
            try {
            const response = await axios.post('/api/verify-role', {
                roleToken: this.roleToken
            });
                // console.log('Role response:', response.data); // Agregar un log para depuración
                this.roleAuth = response.data.role;
                if (this.roleAuth === 'superadministrador') {
                this.availableRoles = [
                    { value: 'superadministrador', text: 'Superadministrador' },
                    { value: 'administrador', text: 'Administrador' },
                    { value: 'usuario', text: 'Usuario' }
                ];
                } else if (this.roleAuth === 'administrador' || this.roleAuth === 'administradorevento') {
                    this.availableRoles = [
                        { value: 'administrador', text: 'Administrador' },
                        { value: 'usuario', text: 'Usuario' }
                    ];
                }
                this.checkAccess(this.roleAuth);
            } catch (error) {
                // console.error('Error verifying role:', error);
                this.roleAuth = null;
            } finally {
                this.roleLoaded = true;
            }
            
            // Este método determina qué roles están disponibles basándose en el usuario que crea el nuevo usuario
            
        },
        checkAccess(role) {            
            if (role === 'usuario') {
                this.toast.error("No tienes permiso para ver esta página.");
                this.goBack();
            }else {                
                this.isAuthorized = true; 
            }
        },
        goBack() {
            this.$router.go(-1); // Navega un paso atrás en el historial del navegador
        },
    }
}
</script>
