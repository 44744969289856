<template>
    <div class="contenedor-form-general">
      <div class="fondologin"></div>
      <LogoRecovery></LogoRecovery>
      <div class="contenedor-form">
        <div class="fondo-contenedor"></div>
        <form @submit.prevent="solicitarRecuperacion">
          
          <p class=" font-bold titulo">Recuperar Contraseña</p>
          <div class="correo">
            <span class="">Dirección de email</span>   
              <input type="email" v-model="email" placeholder="Ingrese su correo electrónico" required>
            
          </div>
          <button type="submit">Enviar Link de Recuperación</button>
        </form>
      </div>
      <div class="caja-blanca">
            <p class="font-bold titulo">¿Ya tienes una cuenta?</p>
            <button @click="goToLogin">Iniciar sesión</button>
        </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import LogoRecovery from './LogoForms/LogoRecovery.vue';
  import { useToast } from 'vue-toastification';
  
  export default {
    data() {
      return {
        email: '',
      };
    },
    components: {
        LogoRecovery
    },
    methods: {
        solicitarRecuperacion() {
            axios.post('/api/recuperar-password', { email: this.email })
                .then(response => {
                    // Asegurar que la respuesta contiene la propiedad 'success'
                    if (response.data && response.data.success) {
                        this.toast.success(response.data.success);
                        setTimeout(() => {
                            this.toast.warning('En breve será redireccionado al inicio de sesión');
                        }, 2000);
                        setTimeout(() => {
                            this.$router.push('/');
                        }, 7000);
                    } else {
                        // Verificar si la respuesta contiene la propiedad 'error'
                        if (response.data && response.data.error) {
                            this.toast.error(response.data.error);
                        } else {
                            // Manejar el caso donde no se recibe el objeto esperado
                            this.toast.error('No se recibió respuesta esperada del servidor.');
                        }
                    }
                })
                .catch(error => {
                    // Aquí capturas el error del catch, no de `response.data`
                    if (error.response && error.response.data) {
                        // Verificar si hay mensaje de error específico del servidor
                        this.toast.error(error.response.data.message || 'Error en el servidor');
                    } else {
                        this.toast.error('Error en el servidor, intenta de nuevo más tarde.');
                    }
                });
    },
      goToLogin() {
            this.$router.push('/login');
        },
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
  };
  </script>
  
<style lang="sass" scoped>
.fondologin
    position: fixed
    min-height: 130vh
    min-width: 100vw
    z-index: -2
    background-repeat: no-repeat
    background-attachment: fixed
    background-size: cover
    background-position: top
    background-image: url(~@/assets/forms/jakob-dalbjorn-cuKJre3nyYc-unsplash.jpg)!important
    top: 0
    // backdrop-filter: blur(10px)
    // background-color: rgba(255, 255, 255, 0.35)
.contenedor-form-general
    // backdrop-filter: blur(30px)
    // background-color: rgba(255, 255, 255, 0.5)
    // backdrop-filter: blur(10px)
    // background-color: rgba(255, 255, 255, 0.35)
    width: 95%
    margin: 0 auto
    max-width: 500px
    padding: 20px
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    height: 100%
    border-radius: 20px
    position: relative
    // padding-top: 140px
    // .fondo-contenedor
    //     position: absolute
    //     z-index: -1
    //     width: 100%
    //     height: 100%
    //     top: 0
    //     bottom: 0
    //     left: 0
    //     right: 0
    //     margin: auto
    //     background: rgba(255,255,255,0.7)
    //     filter: blur(10px)

    .contenedor-form, .caja-blanca
        padding: 20px 25px 15px 25px
        backdrop-filter: blur(10px)
        background-color: rgba(255, 255, 255, 0.35)
        border-radius: 20px
        width: 100%
        max-width: 529px
        margin-top: 20px
        p
            text-align: center!important
        .titulo
            font-size: 32px
            text-align: left
            margin-bottom: 20px
            
        form 
            position: relative
            text-align: left
            .correo, .password
                span
                    display: block
                    width: 100%
                    margin: 0
                    padding: 0
                    font-size: 20px
                    font-weight: bold
                    margin: 15px 0 10px 0
                input
                    width: 100%
                    border: 2px solid rgba(120,120,120, 0.6)
                    padding: 10px 15px
                    border-radius: 30px
                    background: rgba(255,255,255, 0.5)
                    transition: all 0.2s
                    margin-bottom: 20px                    
                    &:focus, &:active                                                
                        background: #fff
                        outline: none
                        border: 2px solid rgba(200,200,200, 1)                        
                        box-shadow: 0 0 3px rgba(155,155,155, 0.8)

            .password
                margin-top: 15px
        button
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
            background: rgba(255,255,255, 0.6)
            color: #000
            margin-top: 15px
            font-size: 0.8em
            border-radius: 30px
            text-align: center
            transition: all 0.2s
            &:hover
              background: rgba(255,255,255, 0.9)
              transform: scale(0.98)
              box-shadow: 0 0 5px rgba(155,155,155, 0.5)
            &:active
              transform: scale(0.97)
              border: 2px solid rgba(0,0,0, 0.7)
        .terminos, .pass-olvidado
            font-size: 0.7em
            max-width: 100%
            margin: 20px auto
            a
                color: #555
                text-decoration: none   
                cursor: pointer
                font-weight: bold
                transition: all 0.2s
                text-decoration: underline
                &:hover
                  color: #000
                  
        .pass-olvidado
            display: flex
            align-items: center
            svg
                transform: scale(0.9)
                margin-left: 5px
        .otros-inicios
            button 
                margin-bottom: 15px                
                display: flex
                align-items: center
                justify-content: centera
                svg
                    transform: scale(0.9)
                    margin-right: 10px
            .google
                background: #529BFE
                color: #fff
            .facebook
                background: #184075
                color: #fff


.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter, .fade-leave-to
    opacity: 0
.move-up-enter-active, .move-up-leave-active
    transition: transform 0.2s ease, opacity 0.2s ease
.move-up-enter, .move-up-leave-to
    transform: translateY(-20px)
    opacity: 0
.slide-fade-enter-active, .slide-fade-leave-active
    transition: all 0.2s ease
.slide-fade-enter, .slide-fade-leave-to
    transform: translateY(10px)
    opacity: 0
.email-placeholder
    cursor: pointer
    position: absolute
    line-height: 0
    opacity: 0
    // top: 0
    // display: none
    height: 0
    transition: all 0.2s
.email-placeholder.active
    display: flex
    position: relative
    height: auto
    line-height: 1
    opacity: 1
    .font-bold
      margin-right: 6px
    svg 
      margin-left: 10px
      transform: scale(0.8)
.password
    opacity: 0
.password.pass-active
    opacity: 1
.logo 
  width: fit-content
  margin: 20px
  padding: 10px
  font-size: 24px 
  font-family: Arial, Helvetica, sans-serif
  font-weight: bold
  text-align: right
  line-height: 0.6
  </style>
  